<template>
    <FormRow style="
    background: rgb(212 216 226 / 87%);
">
        <div class="col-sm-3" style="
    padding: 0px 40px 0px 40px;
    background: rgb(212 216 226 / 87%);
    height: 100vh;
">
            <div :class="$style.logo">
                <img v-if="companyLogo" :src="companyLogo" alt="Company Logo"/>
            </div>
            <form :class="$style.form">
                <Loader v-if="isLoading" />
                <ValidationObserver v-slot="{ passes }">
                    <FormRow>
                        <InputText
                                v-model="username"
                                label="User ID"
                                focused
                                rules="required"
                        />
                    </FormRow>
                    <FormRow>
                        <InputText
                                v-model="password"
                                label="Password"
                                rules="required"
                                type="password"
                        />
                    </FormRow>
                    <Recaptcha :class="$style.recaptchaCenter" :captchaReset="captchaReset"></Recaptcha>
                    <FormRow>
                        <div :class="$style.button">
                            <FormButton type="info" size="big" :fullWidth="true" @click="passes(login)">Sign In</FormButton>
                        </div>
                    <div :class="$style.links">
                    <router-link to="/forgotPassword" :class="$style.forgot">Forgot Password?</router-link>
                    </div>
                    </FormRow>
                </ValidationObserver>
            </form>
            <div :class="$style.tipOfTheDay">
                <b>Tip of the Day </b>
                <br>
                {{ tips }}
            </div>
            <!-- <div :class="$style.logoBottom">
                <img src="../../../assets/images/lira-logo.png" alt="Intranet Logo"/>
            </div> -->
        </div>
        <div  class="col-sm-9" >
        <div :class="$style.login"></div> </div>
        <Footer/>
        <Popup title="Information" :value="warningModal.visible" @close="() => showWarningModal(false)">
            {{ warningModal.message }}
            <div slot="footer">
                <FormButton v-if="warningModal.buttons.confirm" type="success" @click="confirmForceLogin">Confirm
                </FormButton>
                <FormButton v-if="warningModal.buttons.cancel" @click="() => showWarningModal(false)">Cancel
                </FormButton>
                <FormButton v-if="warningModal.buttons.ok" @click="() => showWarningModal(false)">Ok
                </FormButton>
            </div>
        </Popup>
    </FormRow>
    <!-- <div :class="$style.login">
        <div :class="$style.wrapper">
            <div :class="$style.logo">
                <img v-if="companyLogo" :src="companyLogo" alt="Company Logo"/>
            </div>
            <form :class="$style.form">
                <Loader v-if="isLoading" />
                <ValidationObserver v-slot="{ passes }">
                    <FormRow>
                        <InputText
                                v-model="username"
                                label="User ID"
                                focused
                                rules="required"
                        />
                    </FormRow>
                    <FormRow>
                        <InputText
                                v-model="password"
                                label="Password"
                                rules="required"
                                type="password"
                        />
                    </FormRow>
                    <Recaptcha :class="$style.recaptchaCenter" :captchaReset="captchaReset"></Recaptcha>
                    <FormRow>
                        <div :class="$style.button">
                            <FormButton type="info" size="big" :fullWidth="true" @click="passes(login)">Sign In</FormButton>
                        </div>
                    <div :class="$style.links">
                    <router-link to="/forgotPassword" :class="$style.forgot">Forgot Password?</router-link>
                    </div>
                    </FormRow>
                </ValidationObserver>
           
            </form>
            <div :class="$style.tipOfTheDay">
                <b>Tip of the Day </b>
                <br>
                {{ tips }}
            </div>
            <div :class="$style.logoBottom">
                <img src="../../../assets/images/lira-logo.png" alt="Intranet Logo"/>
            </div>
            
        </div>
        <Footer/>
       
    </div> -->
</template>

<script>
    import { loginRequest, getLogo, logoutRequest,getTips} from "./loginActions"; //sagar add getTips api
    import Auth from 'Utils/auth'
    import Ajax from 'Utils/ajax'
    import {mapMutations} from 'vuex';
    import Footer from "Components/layout/Footer";
    import Loader from "Components/layout/Loader";
    import loadingMixin from "Mixins/loadingMixin";
    import { ValidationObserver } from 'vee-validate'
    import Recaptcha from "Components/recaptcha/Recaptcha";
    export default {
        name: "Login",
        data() {
            return {
                username: "",
                password: "",
                companyLogo: '',
                captchaReset: false,
                warningModal: {
                    visible: false,
                    message: '',
                    buttons: {
                        ok: false,
                        confirm: false,
                        cancel: false
                    }
                },
                force_login: false,
                tips:'',
            };
        },
        mixins: [loadingMixin],
        components: {
            Footer,
            ValidationObserver,
            Loader,
            Recaptcha,
        },
        created() {
            getLogo().then(res => {
                this.companyLogo = res.data;
            });
            getTips().then(res => {
                this.tips=res.data[0].Tip
            });
            // Auth.logoutUser();
            // Ajax.removeToken();
            // Ajax.removeToken();
            this.logout();
        },
        methods: {
            ...mapMutations([
                'setUser',
            ]),
            login() {
                const {username, password, force_login} = this;
                loginRequest({
                    username,
                    password,
                    force_login,
                    recaptchaToken : this.$store.state.common.recaptchaToken
                })
                    .then(res =>{this.handleSuccess(res)},
                        err =>{
                            this.captchaReset = true;
                        });
            },
            handleSuccess(response) {
                this.hideLoader();
   
                if (typeof response.force_flag !== 'undefined' && response.force_flag==1) {
                    this.warningModal.message = response.message;
                    this.warningModal.buttons.confirm = true;
                    this.warningModal.buttons.cancel = true;
                    this.warningModal.buttons.ok = false;
                    this.showWarningModal(true);
                } else {
          if (response.code==='202') { // sagar changes  code 202 means inactive user  
            this.warningModal.message = response.message;
            this.warningModal.buttons.confirm = false;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.ok = true;
            this.showWarningModal(true);
          } else {
            Auth.logUser(response);
            Ajax.setToken(Auth.getUserToken());
            this.$store.commit('removeRecaptcha');
                    if(localStorage.savedRoute){
                        var url =  JSON.parse(localStorage.getItem('savedRoute')).url;
                        localStorage.removeItem('savedRoute')
                        this.$router.push(url)
                    }else{
            // this.$router.push({'path': '/dashboard'})
            window.open("/dashboard",'_self');
          }
          }
                  
                }
            },
            showWarningModal(value) {
                this.warningModal.visible = value;
            },
            confirmForceLogin(){
                this.force_login = true;
                this.login();
                this.showWarningModal(false);
            },
            logout(){
                if(localStorage.userData){
                    logoutRequest().then(
                        res => {
                            Auth.logoutUser();
                            Ajax.removeToken();
                            if(localStorage.timeOfNewRefresh){
                                localStorage.removeItem('timeOfNewRefresh');
                            }

                        },
                        err => {
                            if(localStorage.timeOfNewRefresh){
                                localStorage.removeItem('timeOfNewRefresh');
                            }
                            Auth.logoutUser();
                            Ajax.removeToken();
                        }
                    )
                }
                if(!localStorage.userData && this.userData !== null){
                    Auth.logoutUser();
                    Ajax.removeToken();
                }
            },
        }
    };
</script>

<style module lang="scss">
  .links {
        display: flex;
        margin-top: -15px;
        justify-content: end;
        margin-top: 5px;
    }
.form {
        padding: 5px 15px;
        position: relative;
    }
    .new {
        color: #37a0cf;
        font-size: 14px;
    }
    .button {
        padding-top: 5px;
    }
    .login {
        // background: #9ac2d2 url("../../../assets/images/login-bg-v1.jpg") 0 14% no-repeat;
        background: #9ac2d2 url("../../../assets/images/staffImage.jpg") 0 14% no-repeat;
        background-size: cover;
        height: auto;
        min-height: 100vh;
    }

    .formInput {
        margin-bottom: 20px;
    }

    .wrapper {
        width: 330px;
        background: rgba(245, 246, 249, 0.87);
        margin-left: 30px;
        padding: 20px;
        position: absolute;
        bottom: 0;
        top: 0;
        box-shadow: 0 5px 4px rgba(0, 0, 0, 0.24);
    }

    .logo {
        margin: 50px 0 30px;

        > img {
            display: block;
            margin: 0 auto;
        }
    }

    .logoBottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 55px;

        img {
            max-width: 90px;
            display: block;
            margin: 0 auto;
        }
    }
    .recaptchaCenter{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding: 0px 10px;
        & iframe{
            transform: scale(0.9);
        }
        & #rc-anchor-container{
            transform: scale(0.94);
        }
    }
    .tipOfTheDay{
        height: 22px;
    font-size: 12px;
    color: #000;
    font-family: Verdana,Arial,Helvetica,sans-serif;
    }
    body{
overflow: hidden;
    }
    label{
        margin: -8px;
    margin-left: 0px;
    }
</style>
